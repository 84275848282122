.calendario-mintc__title {
    text-transform: uppercase;
    margin-bottom: 1em;
    font-weight: bold;
}

.calendario-mintc__hero {
    background: #E0F0F8;
    padding: 2em;
    text-align: center;
    position: relative;
    font-size: 1.5em;
    margin-bottom: 50px;
}

.calendario-mintc__hero::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #E0F0F8;
    clear: both;
}

.calendario-mintc__hero-message {
    font-size: 1em;
}

.calendario-mintc__medici {
    margin-top: 2em;
}

.float-col-25 {
    float: left;
    width: 25%;
}

.float-col-33 {
    float: left;
    width: 33%;
}

@media screen and (max-width: 1000px) {
    .float-col-25 {
        float: none;
        width: 100%;
    }

    .float-col-33 {
        float: none;
        width: 100%;
    }
}

.float-col-25:nth-child(4n+1),
.float-col-33:nth-child(3n+1) {
    clear: both;
}

.calendario-mintc__medico {
    margin: 0 .5em;
}

.calendario-mintc_prl8 {
    padding-right: 8px;
    padding-left: 8px;
}

.calendario-mintc__medico-spec {
    font-size: 1.2em;
}

.calendario-mintc__month, 
.calendario-mintc__tobedefined {
    background: #ccc;
    padding-top: .4em;
    padding-bottom: .2em;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: .5em;
    margin-top: .5em;
    font-size: 1em;
}

.calendario-mintc__month {
    background: #0087C0;
    text-transform: uppercase;
    color: white;
}

.calendario-mintc__days {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}

.calendario-mintc__medici > div {
    padding-bottom: 30px;
}

.calendario-mintc__medico-head {
    font-size: 1.3em;
    margin-bottom: .3em;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.calendario-mintc__medico-head > div {
    flex: 1 0 50%;
}

.calendario-mintc__btn {
    background: #E9861E;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}